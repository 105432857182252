import { Injectable, EventEmitter } from '@angular/core';

import { IUser } from './aws/helpers/aws-helper-auth';

@Injectable()
export class GlobalVarsService {
  //Used to detect when the sider is collapsed
  siderCollapsed: boolean = true;
  siderCollapsedStream: EventEmitter<boolean> = new EventEmitter();

  //Used to detect the current URL
  currentURL: string = '';
  currentURLStream: EventEmitter<string> = new EventEmitter();

  //Used to keep the current user
  user: IUser = null;
  userStream: EventEmitter<IUser> = new EventEmitter();

  constructor() { }

  getSiderCollapsed(): boolean {
    return this.siderCollapsed;
  }

  setSiderCollapsed(value: boolean): void {
    this.siderCollapsed = value;
    this.siderCollapsedStream.emit(this.siderCollapsed);
  }

  getCurrentURL(): string {
    return this.currentURL;
  }

  setCurrentURL(value: string): void {
    this.currentURL = value;
    this.currentURLStream.emit(this.currentURL);
  }

  getUser(): IUser {
    const user: IUser = this.user ? this.user : JSON.parse(localStorage.getItem('user')) as IUser;
    return user;
  }

  setUser(value: IUser): void {
    this.user = value;
    this.userStream.emit(this.user);

    //Update local storage
    localStorage.removeItem('user');
    if (value !== null && typeof value !== 'undefined') {
      localStorage.setItem('user', JSON.stringify(this.user));
    }
  }
}
