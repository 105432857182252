import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import * as _ from 'lodash';

import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { DateTimeService, DATE_TIME } from 'src/app/shared/services/date-time.service';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { IUser } from 'src/app/shared/services/aws/helpers/aws-helper-auth';
import { DataWorkersService, IWorkerGetData } from 'src/app/shared/services/data/data-workers.service';

export interface IBreadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})
export class CommonLayoutComponent implements OnDestroy {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  contentHeaderDisplay: string;
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor: string;

  user: IUser = null;
  userSubscription: Subscription = null;
  accessDate: string = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeConstantService,
    private globalVarsService: GlobalVarsService,
    private dataWorkersService: DataWorkersService,
    private dateTimeService: DateTimeService,
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['headerDisplay']) {
            return child.snapshot.data['headerDisplay'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      this.contentHeaderDisplay = data;
    });

    //Breadcrumbs
    this.breadcrumbs$ = this.router.events.pipe(
      startWith(new NavigationEnd(0, '/', '/')),
      filter(event => event instanceof NavigationEnd), distinctUntilChanged(),
      map(data => this.buildBreadCrumb(this.activatedRoute.root))
    );

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    //Set access date
    this.setAccessDate(this.globalVarsService.getUser());
    this.userSubscription = this.globalVarsService.userStream.subscribe((user: IUser) => {
      this.setAccessDate(user);
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    let label: string = '';
    let path: string = '/';

    if (route.routeConfig) {
      if (route.routeConfig.data) {
        label = route.routeConfig.data['title'];
        path += route.routeConfig.path;
      }
    }

    const nextUrl = path ? `${url}${path}` : url;
    const breadcrumb = <IBreadcrumb>{ label: label, url: nextUrl };
    const newBreadcrumbs = label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  setAccessDate(user: IUser) {
    //Only get this if user hasn't changed
    if (this.user === null) {
      this.user = user;

      //Get workers data
      this.getWorkerData()
        .then((workerResponse: any) => {
          if (workerResponse) {
            let terminationDate: string = _.get(workerResponse, 'TerminationDate', null);
            
            //Adding test to show count down timer. Only shows for Edgar
            if (parseInt(_.get(workerResponse, 'EmployeeId', '')) === 15780) { 
              terminationDate = '2020-06-01';
            }
            
            if (terminationDate && terminationDate !== '0000-00-00') {
              this.accessDate = this.dateTimeService.format(this.dateTimeService.addDays(terminationDate, environment.access.login), DATE_TIME.formats.fancy);
              this.accessDate += _.get(workerResponse, 'CountryCode', 'NZ') === 'New Zealand' ? ' (NZST)' : ' (AEST)';
            }
          }
        });
    }
  }

  getWorkerData(): Promise<any> {
    if (this.user) {
      const params: IWorkerGetData = {
        employeeId: this.user.id
      };
      return this.dataWorkersService.getOne(params)
        .then((workerResponse: any) => {
          return workerResponse;
        })
        .catch(() => {
          return Promise.reject();
        });
    }

    return Promise.reject();
  }
}