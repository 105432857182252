import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { DATA_PERMISSION_LEVELS } from './data-permission-levels.service';

export const DATA_MODULES_SERVICE = {
  files: {
    id: 'payroll-portal-files',
    url: 'files'
  },

  emails: {
    id: 'payroll-portal-emails',
    url: 'emails'
  }
}

export interface IDataModule {
  id: string,
  url: string,
  name: string,
  permissionLevel: number
}

@Injectable({
  providedIn: 'root'
})
export class DataModulesService {
  constructor() { }

  get(): IDataModule[] {
    const dataModules: IDataModule[] = [
      { id: DATA_MODULES_SERVICE.files.id, url: DATA_MODULES_SERVICE.files.url, name: 'Files', permissionLevel: DATA_PERMISSION_LEVELS.values.basic },
      { id: DATA_MODULES_SERVICE.emails.id, url: DATA_MODULES_SERVICE.emails.url, name: 'Emails', permissionLevel: DATA_PERMISSION_LEVELS.values.basic }
    ];

    return dataModules;
  }

  getById(id: string) {
    return _.find(this.get(), { id: id });
  }

  getByUrl(url: string) {
    return _.find(this.get(), { url: url });
  }
}