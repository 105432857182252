<div class="header">
  <div class="logo logo-dark">
    <a [routerLink]="['/']">
      <img src="assets/images/logo/logo.svg" alt="Logo">
      <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo">
    </a>
  </div>

  <div class="logo logo-white">
    <a [routerLink]="['/']">
      <img src="assets/images/logo/logo-white.png" alt="Logo">
      <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
    </a>
  </div>

  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i class="text-white fas" [ngClass]="{'fa-caret-square-left': !isFolded, 'fa-caret-square-right': isFolded}"></i>
        </a>
      </li>

      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i class="text-white fas" [ngClass]="{'fa-caret-square-left': isExpand, 'fa-caret-square-right': !isExpand}"></i>
        </a>
      </li>
    </ul>

    <ul class="nav-right">
      <li>
        <span class="pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
          <nz-avatar *ngIf="user && user.pic" [nzSrc]="user.pic"></nz-avatar>
          <i *ngIf="!user || (user && !user.pic)" class="fas fa-user text-white font-size-18"></i>
          <span *ngIf="user" class="text-white m-l-5">{{ user.firstName }}</span>
        </span>

        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-5 p-t-10">
            <li class="p-h-20 p-b-15 border-bottom">
              <div class="d-flex m-r-50">
                <sw-worker [id]="user?.id" [link]="false" [fullInfo]="true" [pic]="true" [showPopover]="false"></sw-worker>
              </div>
            </li>

            <li nz-menu-item>
              <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="confirmSignOut()">
                <div>
                  <i class="opacity-04 font-size-16 fas fa-sign-out-alt"></i>
                  <span class="m-l-10">{{ "Sign out" }}</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
    </ul>
  </div>
</div>