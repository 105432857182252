import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

export declare type EventHandler = (...args: any[]) => any;
export declare type Topic = { event: string, args: any[] };

export const EVENTS_SERVICE = {
  events: {
    auth: {
      confirmSignOut: 'auth::confirm_sign_out'
    }
  }
};

@Injectable()
export class EventsService {
  eventEmitter: EventEmitter<Topic> = new EventEmitter();

  publish(event: string, ...args: any[]): void {
    this.eventEmitter.emit({ event: event, args: args });
  }

  subscribe(subscribedEvent: string, handler: EventHandler): Subscription {
    return this.eventEmitter.subscribe((emittedEvent: Topic) => {
      if (emittedEvent.event === subscribedEvent) {
        handler(...emittedEvent.args);
      }
    });
  }
}