import { IModuleRoutes } from 'src/app/app-routing.module';

export const AUTHENTICATION_ROUTES: IModuleRoutes = {
  base: {
    url: 'authentication',
    fullUrl: '/authentication',
    data: {
      title: 'Authentication'
    }
  },

  children: {
    login: {
      url: 'login',
      fullUrl: '/authentication/login',
      data: {
        title: 'Login'
      }
    }
  }
};