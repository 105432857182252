<!-- Containers -->
<ng-container [ngSwitch]="mode">
  <!-- Card -->
  <ng-container *ngSwitchCase="SW_WORKER.modes.card">
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </ng-container>

  <!-- Default -->
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="!worker">
      <ng-container *ngTemplateOutlet="singleLoadingTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="worker">
      <ng-template #workerContentTemplate>
        <ng-container *ngIf="link">
          <a [routerLink]="[worker.DetailsLink]" (click)="onGoToDetails()">
            <ng-container *ngTemplateOutlet="picTemplate; context:{ size: (isLarge ? 120 : null) }"></ng-container>
          </a>

          <div class="media-body" [ngClass]="{'m-l-15': !isSmall, 'm-l-5': isSmall}">
            <a [routerLink]="[worker.DetailsLink]" (click)="onGoToDetails()">
              <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
            </a>

            <ng-container *ngIf="fullInfo">
              <ng-container *ngTemplateOutlet="fullInfoTemplate"></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="!link">
          <ng-container *ngTemplateOutlet="picTemplate; context:{ size: (isLarge ? 120 : null) }"></ng-container>

          <div class="media-body" [ngClass]="{'m-l-15': !isSmall, 'm-l-5': isSmall}">
            <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
            <ng-container *ngIf="fullInfo">
              <ng-container *ngTemplateOutlet="fullInfoTemplate"></ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>

      <div *ngIf="showPopover" class="media align-items-center" nz-popover [nzPopoverContent]="popoverCardTemplate">
        <ng-container *ngTemplateOutlet="workerContentTemplate"></ng-container>
      </div>

      <div *ngIf="!showPopover" class="media align-items-center">
        <ng-container *ngTemplateOutlet="workerContentTemplate"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Templates -->
<ng-template #singleLoadingTemplate>
  <sw-loading [type]="SW_LOADING.types.single" [iconSize]="isSmall ? 24 : null"></sw-loading>
</ng-template>

<!-- Pic -->
<ng-template #picTemplate let-size="size">
  <nz-spin [nzIndicator]="singleLoadingTemplate" [nzSpinning]="worker.loadingPic" [ngClass]="'avatar-spin' + (isLarge ? '' : '-small')">
    <nz-avatar nzIcon="user" [nzSrc]="worker.Pic" [nzSize]="size ? size : (isSmall ? 'small' : (fullInfo ? 'large' : 'default'))"
      [ngClass]="'avatar-worker-details' + (isLarge ? '-large' : '')"></nz-avatar>
  </nz-spin>
</ng-template>

<!-- Name -->
<ng-template #nameTemplate>
  <ng-container *ngIf="isLarge">
    <h2 class="m-b-5" [ngClass]="{'text-primary': link}">{{ worker.FullName }}</h2>
  </ng-container>

  <div *ngIf="!isLarge" [ngClass]="{'font-weight-semibold': fullInfo}">
    {{ worker.FullName }}
  </div>
</ng-template>

<!-- Full info-->
<ng-template #fullInfoTemplate>
  <p class="m-b-0">{{ worker.PortName }} | {{ worker.CountryName }}</p>
  <p class="m-b-0 text-dark font-size-13">{{ worker.FormattedServiceType }}</p>
</ng-template>

<!-- Card -->
<ng-template #cardTemplate let-isLarge>
  <ng-template #actionEdit>
    <a [routerLink]="[worker.DetailsLink]" (click)="onGoToDetails()">
      <button nz-button nzType="default" nzShape="circle" nzSize="small" nz-tooltip [nzTooltipTitle]="'Edit'" class="sw-action-button">
        <!--<i nz-icon nzType="edit" theme="outline"></i>-->
        <i class="fas fa-edit"></i>
      </button>
    </a>
  </ng-template>

  <ng-template #actionEmail>
    <button nz-button nzType="default" nzShape="circle" nzSize="small" [disabled]="!worker.HasFormattedEmail" nz-tooltip [nzTooltipTitle]="'Email'"
      class="sw-action-button">
      <!--<i nz-icon nzType="mail" theme="outline"></i>-->
      <i class="fas fa-envelope"></i>
    </button>
  </ng-template>

  <ng-template #actionCall>
    <button nz-button nzType="default" nzShape="circle" nzSize="small" [disabled]="!worker.HasFormattedPhone" nz-tooltip [nzTooltipTitle]="'Call'"
      class="sw-action-button">
      <!--<i nz-icon nzType="phone" theme="outline"></i>-->
      <i class="fas fa-phone"></i>
    </button>
  </ng-template>

  <ng-template #actionEllipsis>
    <a class="text-gray font-size-18" nz-dropdown nzTooltipContent="bottomRight" nzTrigger="click" [nzDropdownMenu]="workersListSetting">
      <i nz-icon nzType="ellipsis" nz-tooltip [nzTooltipTitle]="'More'" nzTooltipContent="bottom"></i>
    </a>

    <nz-dropdown-menu #workersListSetting="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item>
          <a nz-popconfirm [nzPopconfirmTitle]="'Are you sure you want to terminate this worker?'" [nzOkText]="'Ok'" [nzCancelText]="'Cancel'"
            nzTooltipContent="top">
            <i nz-icon nzType="user-delete" theme="outline"></i>
            <span class="m-l-10">{{ "Terminate" }}</span>
          </a>
        </li>

        <li nz-menu-item>
          <a nz-popconfirm [nzPopconfirmTitle]="'Are you sure you want to delete this worker?'" [nzOkText]="'Ok'" [nzCancelText]="'Cancel'"
            nzTooltipContent="top" (nzOnConfirm)="confirmDelete()">
            <i nz-icon nzType="delete" theme="outline"></i>
            <span class="m-l-10">{{ "Delete" }}</span>
          </a>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>

  <nz-card [nzSize]="'small'" [nzActions]="[actionEdit, actionEmail, actionCall]">
    <div class="text-center">
      <a [routerLink]="[worker.DetailsLink]" (click)="onGoToDetails()">
        <ng-container *ngTemplateOutlet="picTemplate; context:{ size: 120 }"></ng-container>
      </a>

      <a [routerLink]="[worker.DetailsLink]" (click)="onGoToDetails()">
        <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      </a>

      <ng-container *ngTemplateOutlet="fullInfoTemplate"></ng-container>
      <span class="text-muted">{{ worker.FormattedEmail }}</span><br>
      <span class="text-muted">{{ worker.FormattedPhone }}</span>
    </div>
  </nz-card>
</ng-template>

<!-- Popover card -->
<ng-template #popoverCardTemplate>
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</ng-template>