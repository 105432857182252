// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  appVersion: require('./../../package.json').version,

  //API constants
  api: {
    name: 'Payroll_Portal',
    paths: {
      employees: '/employees/',
      payslips: '/payslips/',
      payslipFile: '/payslips/files/',
      summaries: '/summaries/',
      summariesFiles: '/summaries/files/'
    }
  },

  //Amplify config
  amplify: {
    //Auth
    Auth: {
      identityPoolId: 'ap-southeast-2:ac994064-1504-47cf-8efc-1294b73b4bb0',
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_4scqBc7zT',
      userPoolWebClientId: '2lplef5gg045b4srqpg65ngoh7',
      oauth: {}
    },
    //API
    API: {
      endpoints: [
        {
          name: "Payroll_Portal",
          endpoint: "https://api-ep.development.swissport.com.au/v1" //Payroll_Portal, id: s0kq61ww8d
        }
      ]
    },
  },

  //oAuth
  oAuth: {
    baseUrl: 'https://clockwork-dev.auth.ap-southeast-2.amazoncognito.com/oauth2',
    clientId: '2lplef5gg045b4srqpg65ngoh7',
    redirectUri: 'http://localhost:4500/authentication/login',
    ssoUrl: '',
    tokenUrl: '',
    cognitoPoolUrl: '',
    logoutUrl: 'https://clockwork-dev.auth.ap-southeast-2.amazoncognito.com/logout',
    logoutRedirectUri: 'http://localhost:4500/authentication/login',
  },

  session: {
    timeout: 45 * 60 * 1000, // 45 minutes,
    refreshTime: 45 * 60 * 1000 // 45 minutes
  },

  access: {
    login: 45, //days
    emailLink: 'https://sso.development.swissport.com.au/sso/saml2/idp/SSOService.php?spentityid=google.com/a/aerocare.com.au&RelayState=https://mail.google.com/a/aerocare.com.au'
  }
};

//oAuth
environment.oAuth.cognitoPoolUrl = 'cognito-idp.ap-southeast-2.amazonaws.com/' + environment.amplify.Auth.userPoolId;
environment.oAuth.ssoUrl = environment.oAuth.baseUrl + '/authorize?identity_provider=Swissport-Development&response_type=CODE&redirect_uri=' + environment.oAuth.redirectUri + '&client_id=' + environment.oAuth.clientId;
environment.oAuth.tokenUrl = environment.oAuth.baseUrl + '/token';

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.