import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { IUser, IUserModule } from 'src/app/shared/services/aws/helpers/aws-helper-auth';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { FILES_ROUTES } from 'src/app/modules/files/files-routes';
import { DATA_MODULES_SERVICE, IDataModule, DataModulesService } from 'src/app/shared/services/data/data-modules.service';
import { EMAILS_ROUTES } from 'src/app/modules/emails/emails-routes';

export interface ISideNavRoute {
  id: string,
  path: string,
  title: string,
  iconType: '' | 'nzIcon' | 'fontawesome',
  iconTheme: '' | 'fab' | 'far' | 'fas' | 'fill' | 'outline' | 'twotone',
  icon: string,
  submenu: ISideNavRoute[],
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent implements OnInit, OnDestroy {
  isFolded: boolean;
  isFoldedSubscription: Subscription;

  isSideNavDark: boolean;
  isSideNavDarkSubscription: Subscription;

  sideNavRoutes: ISideNavRoute[] = [];
  userRoutes: ISideNavRoute[] = [];

  user: IUser = null;
  userSubscription: Subscription;

  constructor(
    private themeService: ThemeConstantService,
    private globalVarsService: GlobalVarsService,
    private dataModulesService: DataModulesService,
  ) { }

  ngOnInit(): void {
    this.isFoldedSubscription = this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.isSideNavDarkSubscription = this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);

    //Set side nav routes
    this.setSideNavRoutes();

    //Set user
    this.user = this.globalVarsService.getUser();
    this.userSubscription = this.globalVarsService.userStream.subscribe((user: IUser) => {
      this.user = user;
      this.setUserRoutes();
    });

    //Set user routes
    this.setUserRoutes();
  }

  ngOnDestroy(): void {
    if (this.isFoldedSubscription) {
      this.isFoldedSubscription.unsubscribe();
    }

    if (this.isSideNavDarkSubscription) {
      this.isSideNavDarkSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  setSideNavRoutes() {
    //Files
    this.sideNavRoutes.push({
      path: FILES_ROUTES.children.payslips.fullUrl,
      title: FILES_ROUTES.base.data.title,
      iconType: 'fontawesome',
      iconTheme: 'far',
      icon: 'fa-folder-open',
      id: DATA_MODULES_SERVICE.files.id,
      submenu: [
        //Payslips
        {
          path: FILES_ROUTES.children.payslips.fullUrl,
          title: FILES_ROUTES.children.payslips.data.title,
          iconType: 'fontawesome',
          iconTheme: 'fas',
          icon: 'fa-file-invoice-dollar',
          id: DATA_MODULES_SERVICE.files.id,
          submenu: []
        },
        
        //Files
        {
          path: FILES_ROUTES.children.files.fullUrl,
          title: FILES_ROUTES.children.files.data.title,
          iconType: 'fontawesome',
          iconTheme: 'fas',
          icon: 'fa-file',
          id: DATA_MODULES_SERVICE.files.id,
          submenu: []
        },
      ]
    });

    //Emails
    this.sideNavRoutes.push({
      path: EMAILS_ROUTES.children.access.fullUrl,
      title: EMAILS_ROUTES.base.data.title,
      iconType: 'fontawesome',
      iconTheme: 'far',
      icon: 'fa-envelope',
      id: DATA_MODULES_SERVICE.emails.id,
      submenu: []
    });
  }

  setUserRoutes() {
    this.userRoutes = [];
    if (this.user) {
      this.userRoutes = _.filter(this.sideNavRoutes, (menuItem: ISideNavRoute) => {
        const userModule: IUserModule = _.find(this.user.modules, { id: menuItem.id });
        const dataModule: IDataModule = this.dataModulesService.getById(menuItem.id);
        return (userModule && dataModule && userModule.permissionLevel >= dataModule.permissionLevel);
      });
    }
  }
}