import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

//Date functions - https://date-fns.org/v2.9.0/docs/I18n
import { toDate, format } from 'date-fns-tz';
import { enAU, es } from 'date-fns/locale';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import isEqual from 'date-fns/isEqual';
import isBefore from 'date-fns/isBefore';
import isWeekend from 'date-fns/isWeekend';
import isToday from 'date-fns/isToday';

import { GlobalVarsService } from './global-vars.service';
import { IUser } from './aws/helpers/aws-helper-auth';

export const DATE_TIME = {
  formats: {
    date: 'yyyy-MM-dd',
    year: 'yyyy',
    fancy: 'MMM dd, yyyy'
  }
};

@Injectable()
export class DateTimeService implements OnDestroy {
  user: IUser = JSON.parse(localStorage.getItem('user')) as IUser;
  userSubscription: Subscription;

  constructor(
    private globalVarsService: GlobalVarsService
  ) {
    this.userSubscription = this.globalVarsService.userStream.subscribe((user: IUser) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  get(date?: Date | string): Date {
    return date instanceof Date ? date : toDate(typeof date === 'string' ? date : new Date());
  }

  format(date?: Date | string, defaultFormat?: string): string {
    return format(this.get(date), (defaultFormat ? defaultFormat : DATE_TIME.formats.fancy), { locale: this.getLocale() });
    //const formattedDate = format(this.get(date), (defaultFormat ? defaultFormat : DATE_TIME.formats.fancy), { locale: this.getLocale() });
    //return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  private getLocale() {
    let locale: Locale = enAU;

    /*if (this.user) {
      switch (this.user.language) {
        case AWS_AUTH.languages.spanish_us.code:
          locale = es;
          break;
      }
    }*/

    return locale;
  }

  getStartOfWeek(date?: Date | string): Date {
    return startOfWeek(this.get(date));
  }

  getEndOfWeek(date?: Date | string): Date {
    return endOfWeek(this.get(date));
  }

  getStartOfMonth(date?: Date | string): Date {
    return startOfMonth(this.get(date));
  }

  getEndOfMonth(date?: Date | string): Date {
    return endOfMonth(this.get(date));
  }

  addMonths(date: Date | string, months: number): Date {
    return addMonths(this.get(date), months);
  }

  addDays(date: Date | string, days: number): Date {
    return addDays(this.get(date), days);
  }

  getYear(date: Date | string): number {
    return getYear(this.get(date));
  }

  getMonth(date: Date | string): number {
    return getMonth(this.get(date));
  }

  isEqual(dateLeft: Date | string, dateRight: Date | string): boolean {
    return isEqual(this.get(dateLeft), this.get(dateRight));
  }

  isBefore(dateLeft: Date | string, dateRight: Date | string): boolean {
    return isBefore(this.get(dateLeft), this.get(dateRight));
  }

  isWeekend(date: Date | string): boolean {
    return isWeekend(this.get(date));
  }

  isToday(date: Date | string): boolean {
    return isToday(this.get(date));
  }
}