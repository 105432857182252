<perfect-scrollbar class="side-nav" sideNav>
  <!--<i class="desktop-toggle toggle-menu-icon fas" [ngClass]="{'fa-caret-square-left': !isFolded, 'fa-caret-square-right': isFolded}" (click)="toggleFold()"></i>-->

  <!-- First Level -->
  <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{'ant-menu-inline-collapsed': isFolded, 'ant-menu-dark' : isSideNavDark}">
    <li [ngClass]="item.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline' : 'ant-menu-item'" *ngFor="let item of userRoutes"
      [routerLinkActive]="item.submenu.length > 0 ? ['ant-menu-submenu-open'] : ['ant-menu-item-selected']" [routerLinkActiveOptions]="{exact: true}">
      <a *ngIf="item.submenu.length > 0" href="javascript:void(0);" class="ant-menu-submenu-title">
        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
        <i *ngIf="item.iconType == 'fontawesome'" [ngClass]="[item.iconTheme , item.icon]"></i>
        <span *ngIf="!isFolded" class="m-l-10">{{ item.title }}</span>
        <i *ngIf="!isFolded" class="m-l-10" class="ant-menu-submenu-arrow"></i>
      </a>

      <a *ngIf="item.submenu.length === 0" [routerLink]="item.path">
        <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
        <i *ngIf="item.iconType == 'fontawesome'" [ngClass]="[item.iconTheme , item.icon]"></i>
        <span *ngIf="!isFolded" class="m-l-10">{{ item.title }}</span>
      </a>

      <!-- Second Level -->
      <ul *ngIf="item.submenu.length > 0" class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu">
        <li [ngClass]="subItem.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline' : 'ant-menu-item'" *ngFor="let subItem of item.submenu"
          [routerLinkActive]="subItem.submenu.length > 0 ? ['ant-menu-submenu-open'] : ['ant-menu-item-selected']" [routerLinkActiveOptions]="{exact:
          true}">
          <a *ngIf="subItem.submenu.length > 0" href="javascript:void(0);" class="ant-menu-submenu-title p-l-50">
            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
            <span>{{ subItem.title }}</span>
            <i class="ant-menu-submenu-arrow"></i>
          </a>

          <a *ngIf="subItem.submenu.length === 0" [routerLink]="subItem.path" class="p-l-30">
            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
            <span>{{ subItem.title }}</span>
          </a>

          <!-- Third Level -->
          <ul *ngIf="subItem.submenu.length > 0" class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu">
            <li [ngClass]="subSubItem.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline' : 'ant-menu-item'" *ngFor="let subSubItem of subItem.submenu"
              [routerLinkActive]="['ant-menu-item-selected']" [routerLinkActiveOptions]="{exact: true}">
              <a *ngIf="subSubItem.submenu.length > 0" href="javascript:void(0);" class="ant-menu-submenu-title">
                <i *ngIf="subSubItem.iconType == 'nzIcon'" nz-icon [nzType]="subSubItem.icon" [theme]="subSubItem.iconTheme"></i>
                <i *ngIf="subSubItem.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[subSubItem.iconTheme , subSubItem.icon]"></i>
                <span>{{ subSubItem.title }}</span>
                <i class="ant-menu-submenu-arrow"></i>
              </a>

              <a *ngIf="subSubItem.submenu.length === 0" [routerLink]="subSubItem.path" [ngClass]="{'p-l-30': isFolded, 'p-l-50' : !isFolded}">
                <i *ngIf="subSubItem.iconType == 'nzIcon'" nz-icon [nzType]="subSubItem.icon" [theme]="subSubItem.iconTheme"></i>
                <i *ngIf="subSubItem.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[subSubItem.iconTheme , subSubItem.icon]"></i>
                <span>{{ subSubItem.title }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</perfect-scrollbar>