import { IModuleRoutes } from 'src/app/app-routing.module';

export const FILES_ROUTES: IModuleRoutes = {
  base: {
    url: 'files',
    fullUrl: '/files',
    data: {
      title: 'Files'
    }
  },

  children: {
    payslips: {
      url: 'payslips',
      fullUrl: '/files/payslips',
      data: {
        title: 'Payslips'
      }
    },

    files: {
      url: 'others',
      fullUrl: '/files/others',
      data: {
        title: 'Others'
      }
    }
  }
};