import { IModuleRoutes } from 'src/app/app-routing.module';

export const EMAILS_ROUTES: IModuleRoutes = {
  base: {
    url: 'emails',
    fullUrl: '/emails',
    data: {
      title: 'Emails'
    }
  },

  children: {
    access: {
      url: 'access',
      fullUrl: '/emails/access',
      data: {
        title: 'Access'
      }
    }
  }
};