import { Component, OnInit, Input } from '@angular/core';

export const SW_SKELETON = {
  types: {
    permission: 'single'
  }
};

@Component({
  selector: 'sw-skeleton',
  templateUrl: './sw-skeleton.component.html'
})
export class SWSkeletonComponent implements OnInit {
  @Input() type: string = '';
  @Input() tooltipTitle: string = '';
  @Input() rows: number = 2;
  @Input() animated: boolean = false;
  @Input() avatar: boolean = false;

  constructor() { }

  ngOnInit() {
    //Set permissions title
    if (this.type === SW_SKELETON.types.permission && this.tooltipTitle === '') {
      this.tooltipTitle = 'You dont have enough permissions to see this information. Please contact your manager.';
    }
  }
}