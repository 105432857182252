import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { SharedModule } from '../../../shared/shared.module';

import { HeaderComponent } from "./header/header.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { FooterComponent } from "./footer/footer.component";

import { SideNavDirective } from "../../../shared/directives/side-nav.directive";
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

@NgModule({
  exports: [
    HeaderComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent
  ],

  imports: [
    RouterModule,
    SharedModule
  ],

  declarations: [
    HeaderComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent
  ],

  providers: [
    ThemeConstantService
  ]
})
export class CommonLayoutComponentsModule { }