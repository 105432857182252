import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_Routes } from "./layouts/full-layout/full-layout.routes";
import { CommonLayoutRoutes } from "./layouts/common-layout/common-layout.routes";

export interface IModuleRouteData { 
  title?: string
}

export interface IModuleRoute {
  url: string,
  fullUrl: string,
  children?: any,
  data?: IModuleRouteData
};

export interface IModuleRoutes {
  base: IModuleRoute,
  children?: {
    [key: string]: IModuleRoute
  }
};

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/authentication/login',
    pathMatch: 'full',
  },

  {
    path: '',
    component: CommonLayoutComponent,
    children: CommonLayoutRoutes
  },

  {
    path: '',
    component: FullLayoutComponent,
    children: FullLayout_Routes
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'enabled'
    })
  ],

  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }