import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

//NgZorro modules
import { NgZorroModule } from '../ng-zorro.module';

//CDK
import { DragDropModule } from '@angular/cdk/drag-drop';

//Templates
import { ComponentsModule } from './components/components.module';

//Guards
import { GuardsModule } from './guards/guards.module';

//Services
import { ThemeConstantService } from './services/theme-constant.service';
import { DateTimeService } from './services/date-time.service';
import { ModalService } from './services/modal.service';
import { MessageService } from './services/message.service';
import { NotificationService } from './services/notification.service';
import { GlobalVarsService } from './services/global-vars.service';
import { QuillService } from './services/quill.service';
import { EventsService } from './services/events.service';
import { ConsoleService } from './services/console.service';

import { AWSAuthService } from './services/aws/helpers/aws-helper-auth';
import { AWSAPIService } from './services/aws/helpers/aws-helper-api';
import { AWSDataEmployeesService } from './services/aws/data/aws-data-employees';

import { DataWorkersService } from './services/data/data-workers.service';
import { DataCountriesService } from './services/data/data-countries.service';
import { DataLanguagesService } from './services/data/data-languages.service';
import { DataPermissionLevelsService } from './services/data/data-permission-levels.service';
import { DataModulesService } from './services/data/data-modules.service';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    DragDropModule,
    ComponentsModule,
    GuardsModule,

    //NgZorro modules
    NgZorroModule,
  ],

  imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    DragDropModule,
    ComponentsModule,
    GuardsModule,

    //NgZorro modules
    NgZorroModule,
  ],

  providers: [
    ThemeConstantService,
    DateTimeService,
    ModalService,
    MessageService,
    NotificationService,
    GlobalVarsService,
    QuillService,
    EventsService,
    ConsoleService,

    AWSAuthService,
    AWSAPIService,
    AWSDataEmployeesService,

    DataWorkersService,
    DataCountriesService,
    DataLanguagesService,
    DataPermissionLevelsService,
    DataModulesService,
  ]
})
export class SharedModule { }