import { Component, OnInit, Input } from '@angular/core';

export const SW_LOADING = {
  types: {
    single: 'single',
    multiple: 'multiple'
  }
};

@Component({
  selector: 'sw-loading',
  templateUrl: './sw-loading.component.html'
})
export class SWLoadingComponent implements OnInit {
  @Input() content: string = '';
  @Input() type: string = SW_LOADING.types.multiple;
  @Input() iconSize: number;

  private icon: string = '';

  constructor() { }

  ngOnInit() {
    //Set icon
    this.icon = this.type === SW_LOADING.types.single ? '/assets/images/icons/animated/gear.svg' : '/assets/images/icons/animated/gears.svg';

    //Set icon size
    this.iconSize = this.iconSize ? this.iconSize : (this.type === SW_LOADING.types.single ? 40 : 48);
  }
}