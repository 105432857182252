import { Injectable } from '@angular/core';

import { IAWSEmployee, IAWSDataEmployeeGetParams, AWSDataEmployeesService } from '../aws/data/aws-data-employees';
import { DateTimeService, DATE_TIME } from '../date-time.service';
import { DATA_LANGUAGES_SERVICE } from './data-languages.service';

export interface IDataWorker extends IAWSEmployee {
  FullName?: string,
  FormattedAddress?: string,
  FormattedEmergencyAddress?: string,
  FormattedStartDate?: string,
  FormattedServiceType?: string,
  HasFormattedEmail?: boolean,
  FormattedEmail?: string,
  HasFormattedPhone?: boolean,
  FormattedPhone?: string,
  DetailsLink?: string,
}

export interface IWorkerGetData extends IAWSDataEmployeeGetParams { }

export interface IAddress {
  address: string,
  suburb: string,
  postcode: string,
  stateId: string,
  countryId: string,
}

@Injectable({
  providedIn: 'root'
})
export class DataWorkersService {
  constructor(
    private AWSDataEmployeesService: AWSDataEmployeesService,
    private dateTimeService: DateTimeService
  ) { }

  get(params?: IWorkerGetData): Promise<any> {
    return this.AWSDataEmployeesService.get(params)
      .then((dataEmployeeResponse) => {
        let response: any = null;
        if (dataEmployeeResponse) {
          let workers: IDataWorker[] = [];
          if (!params.employeeId) {
            workers = dataEmployeeResponse.body['data'] as IDataWorker[];
          } else {
            workers = dataEmployeeResponse.body as IDataWorker[];
          }

          //Map worker
          for (let worker of workers) {
            this.mapWorker(worker);
          }

          //Set response
          if (!params.employeeId) {
            dataEmployeeResponse.body['data'] = workers;
            response = dataEmployeeResponse.body;
          } else {
            response = workers;
          }
        }

        return response;
      });
  }

  getOne(params: IWorkerGetData): Promise<IDataWorker> {
    return this.get(params)
      .then((employees) => {
        let employee: IDataWorker = null;

        if (Array.isArray(employees) && employees.length > 0) {
          employee = employees[0];
        }

        //Language
        employee.LanguageCode = DATA_LANGUAGES_SERVICE.keys.english_au;
        if (employee.EmployeeId === 15780) {
          employee.LanguageCode = DATA_LANGUAGES_SERVICE.keys.spanish_us;
        }

        return employee;
      });
  }

  mapWorker(worker: IDataWorker) {
    //Full name
    worker.FullName = worker.FirstName.trim() + ' ' + worker.LastName.trim();

    //Address
    const address: IAddress = {
      address: worker.StreetAddress,
      suburb: worker.Suburb,
      postcode: worker.PostCode,
      stateId: worker.State,
      countryId: worker.CountryCode,
    };
    worker.FormattedAddress = this.formatAddress(address);

    //Emergency Contact Address
    const emergencyContactAddress: IAddress = {
      address: worker.EmergencyStreetAddress,
      suburb: worker.EmergencySuburb,
      postcode: null,
      stateId: null,
      countryId: null,
    };
    worker.FormattedEmergencyAddress = this.formatAddress(emergencyContactAddress);

    //Start date
    worker.FormattedStartDate = this.formatDate(worker.StartDate);

    //Formatted service type
    worker.FormattedServiceType = worker.ServiceTypeName ? worker.ServiceTypeName : '(No area found)';

    //Formatted email
    worker.HasFormattedEmail = worker.Email ? true : false;
    worker.FormattedEmail = worker.Email ? worker.Email : '(No email found)';

    //Formatted phone
    worker.HasFormattedPhone = (worker.Mobile || worker.Telephone) ? true : false;
    worker.FormattedPhone = worker.Mobile ? worker.Mobile : (worker.Telephone ? worker.Telephone : '(No number found)');

    //Details link
    //worker.DetailsLink = HR_ROUTES.children.workers.children.details.fullUrl.replace(':id', worker.EmployeeId);
  }

  formatDate(date: string) {
    return this.dateTimeService.format(date, DATE_TIME.formats.fancy);
  }

  formatAddress(address: IAddress, placeHolder?: string): string {
    placeHolder = placeHolder ? placeHolder : '(none provided)';
    let formattedAddress: string = placeHolder;

    if (address) {
      const addressParts = ['address', 'suburb', 'postcode', 'stateId', 'countryId'];
      for (let addressPart of addressParts) {
        if (address[addressPart]) {
          formattedAddress = formattedAddress === placeHolder ? '' : formattedAddress;
          formattedAddress += (formattedAddress !== '' ? '. ' : '') + (address[addressPart] ? address[addressPart] : '');
        }
      }
    }

    return formattedAddress;
  }

  getPic(picLink: string): Promise<string> {
    if (picLink) {
      const params: IAWSDataEmployeeGetParams = {
        picLink: picLink
      }

      return this.AWSDataEmployeesService.getWorkerPic(params)
        .then((picResponse) => {
          let response: any = null;
          if (picResponse && picResponse.body) {
            response = this.AWSDataEmployeesService.sanitizePic(picResponse.body);
          }

          return response;
        });
    } else {
      return Promise.resolve(null);
    }
  }
}