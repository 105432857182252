import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

import { SW_LOADING } from '../loading/sw-loading.component';
import { DataWorkersService, IWorkerGetData, IDataWorker } from 'src/app/shared/services/data/data-workers.service';
import { WORKER_DEFAULT_IMG } from 'src/app/shared/services/aws/data/aws-data-employees';

export interface IWorker extends IDataWorker {
  loadingPic?: boolean,
}

export const SW_WORKER = {
  modes: {
    default: 'default',
    card: 'card'
  },

  sizes: {
    default: 'default',
    small: 'small',
    large: 'large'
  }
};

@Component({
  selector: 'sw-worker',
  templateUrl: './sw-worker.component.html'
})
export class SWWorkerComponent implements OnInit, OnChanges {
  SW_LOADING = SW_LOADING;
  SW_WORKER = SW_WORKER;

  @Input() id: number;
  @Input() worker: IWorker;
  @Input() link: boolean = true;
  @Input() pic: boolean = false;
  @Input() mode: string = SW_WORKER.modes.default;
  @Input() size: string = SW_WORKER.sizes.default;
  @Input() fullInfo: boolean = false;
  @Input() showPopover: boolean = true;

  @Output() goToDetails: EventEmitter<any> = new EventEmitter();
  
  loading: boolean;
  isSmall: boolean = false;
  isLarge: boolean = false;

  constructor(
    private dataWorkersService: DataWorkersService
  ) { }

  ngOnInit() {
    //Load worker
    this.loadWorker();

    //Set isSmall
    this.isSmall = this.size === SW_WORKER.sizes.small;

    //Set isLarge
    this.isLarge = this.size === SW_WORKER.sizes.large;
  }

  ngOnChanges(changes: SimpleChanges) {
    //Check if we need to reload worker
    if (changes.id) {
      this.loadWorker();
    }
  }

  loadWorker() {
    this.loading = true;

    //Load worker if not provided
    if (this.id) {
      let getParams: IWorkerGetData = {
        employeeId: this.id,
        loadPic: false
      };

      this.dataWorkersService.getOne(getParams)
        .then((worker) => {
          this.worker = worker;

          //Get pic
          this.loadPic();

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.loadPic();
      this.loading = false;
    }
  }

  loadPic() { 
    if (this.pic && this.worker && this.worker.Pic === WORKER_DEFAULT_IMG) {
      this.worker.loadingPic = true;

      const getParams: IWorkerGetData = {
        employeeId: this.worker.EmployeeId,
        loadPic: true
      };
      this.dataWorkersService.getOne(getParams)
        .then((worker) => {
          this.worker = worker;
          this.worker.loadingPic = false;
        })
        .catch(() => {
          this.worker.loadingPic = false;
        });
    }
  }

  onGoToDetails() {
    this.goToDetails.emit(true);
  }
}