import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { DataWorkersService, IWorkerGetData } from 'src/app/shared/services/data/data-workers.service';
import { IUser, AWSAuthService } from 'src/app/shared/services/aws/helpers/aws-helper-auth';
import { ModalService, IModalOptions } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnDestroy, OnInit {
  isFolded: boolean;
  isExpand: boolean;

  user: IUser = null;
  userSubscription: Subscription = null;

  constructor(
    private themeService: ThemeConstantService,
    private globalVarsService: GlobalVarsService,
    private dataWorkersService: DataWorkersService,
    private AuthService: AWSAuthService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    //Set user
    this.setUser(this.globalVarsService.getUser());
    this.userSubscription = this.globalVarsService.userStream.subscribe((user: IUser) => {
      this.setUser(user);
    });

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  confirmSignOut() {
    const options: IModalOptions = {
      nzContent: 'Are you sure you want to sign out?',
      nzOkText: 'Yes',
      nzOnOk: () => { this.performSignOut() },
    };
    this.modalService.presentConfirm(options);
  }

  performSignOut() {
    const options: IModalOptions = {
      nzContent: 'Signing you out...'
    };
    this.modalService.presentHardLoader(options);
    this.AuthService.signOut();
  }

  setUser(user: IUser) {
    this.user = user;

    //Get pic and role
    if (user && !user.pic) {
      const params: IWorkerGetData = {
        employeeId: user.id,
        loadPic: true
      };
      this.dataWorkersService.getOne(params)
        .then((workerResponse) => {
          this.user.role = workerResponse.ServiceTypeName;
          this.user.pic = workerResponse.Pic;
          this.user.detailsLink = workerResponse.DetailsLink;
        });
    }
  }
}