//Wrapper for the AWS Amplify Auth category https://aws-amplify.github.io/amplify-js/media/authentication_guide
import { Injectable } from '@angular/core';
import { ConsoleService } from '../../console.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import Auth from '@aws-amplify/auth';
import { FederatedUser } from 'node_modules/@aws-amplify/auth/lib-esm/types/Auth.d';
import { HttpClient } from '@angular/common/http';

import { AWSClass } from '../aws-class';
import { ModalService } from '../../modal.service';
import { DATA_LANGUAGES_SERVICE } from '../../data/data-languages.service';
import { DATA_PERMISSION_LEVELS } from '../../data/data-permission-levels.service';
import { DATA_MODULES_SERVICE } from '../../data/data-modules.service';
import { GlobalVarsService } from '../../global-vars.service';

export const AWS_AUTH = {
  SIGN_IN_OK: 0,
  SIGN_UP_CONFIRM_USER_CODE: -300,
  RESET_PASSWORD_CONFIRM_PASSWORD_CODE: -301,

  SIGN_IN_ERROR_UNKNOW: -1,
  SIGN_IN_ERROR_TOO_MANY_ATTEMPTS: -2,
  SIGN_IN_ERROR_USER_DISABLED: -3,

  SIGN_UP_ERROR_UNKNOW: -100,
  SIGN_UP_ERROR_CONFIRM_USER_CODE: -101,
  SIGN_UP_ERROR_INSECURE_PASSWORD: -102,

  RESET_PASSWORD_ERROR_CONFIRM_PASSWORD_CODE: -201,

  JWT_PASSWORD_REQUIRED_CODE: 'PasswordResetRequiredException',
  JWT_NOT_AUTHORIZED_CODE: 'NotAuthorizedException',
  JWT_USER_NOT_FOUND_CODE: 'UserNotFoundException',
  JWT_NETWORK_ERROR_CODE: 'NetworkError',
  JWT_NEW_PASSWORD_REQUIRED_CODE: 'NEW_PASSWORD_REQUIRED',
  JWT_NO_CURRENT_USER_ERROR_MESSAGE: 'No current user',
  JWT_USER_DISABLED_MESSAGE: 'User is disabled',

  COGNITO_ERROR_USER_NOT_CONFIRMED_CODE: 'UserNotConfirmedException'
};

export interface IAWSAuthResponse {
  body: any,
  statusCode: number
}

export interface IUser {
  id?: number,
  firstName?: string,
  lastName?: string,
  formattedName?: string,
  pic?: string,
  role?: string,
  detailsLink?: string,

  language?: string,
  modules?: IUserModule[]
};

export interface IUserModule {
  id?: string,
  permissionLevel?: number
};

export interface IFederatedUser extends FederatedUser {
  lastName?: string,
  employeeId?: number,
}

@Injectable()
export class AWSAuthService extends AWSClass {
  private requestingTokens: boolean = false;

  constructor(
    public modalService: ModalService,
    public consoleService: ConsoleService,
    private http: HttpClient,
    private globalVarsService: GlobalVarsService,
  ) {
    //Call extending class constructor
    super(modalService, consoleService);
  }

  isValidSession(): Promise<boolean> {
    //Check if there's a valid session
    return Auth.currentCredentials()
      .then((currentCredentials) => {
        if (currentCredentials && currentCredentials.authenticated) {
          return Promise.resolve(true);
        } else {
          return Promise.reject(false);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  signIn(username: string, password: string): Promise<boolean> {
    return Auth.signIn(username, password)
      .then((user) => {
        if (user) {
          //Check if user can log in. A challenge mighe be required first (for example force to change password)
          const signInUserSession = _.get(user, 'signInUserSession', null);
          if (!signInUserSession) {
            return Promise.reject({ code: _.get(user, 'challengeName', null) });
          } else {
            localStorage.removeItem('lastApiCallTime');
            return Promise.resolve(true);
          }
        } else {
          return Promise.reject(false);
        }
      })
      .catch((error) => {
        //Default error message
        const errorMessage = _.get(error, 'message', null);
        let errorContent: string = 'An unhandled error ocurred.' + (errorMessage ? (' Message: ' + errorMessage) : '');

        //Check if a code was sent and we can provide a better message
        const errorCode = _.get(error, 'code', null);
        switch (errorCode) {
          case AWS_AUTH.JWT_USER_NOT_FOUND_CODE:
            errorContent = 'User not found. Please make sure you have logged in at least once in the mobile app first.';
            break;
          case AWS_AUTH.JWT_NOT_AUTHORIZED_CODE:
            errorContent = 'Combination of Username and Password is not valid.';
            break;
          case AWS_AUTH.JWT_NEW_PASSWORD_REQUIRED_CODE:
            errorContent = 'You must change your password before logging in.';
            break;
          case AWS_AUTH.COGNITO_ERROR_USER_NOT_CONFIRMED_CODE:
            errorContent = 'Your user hasn\'t been confirmed. Please login in the mobile app and follow the instructions.';
            break;
        }

        return Promise.reject(this.getErrorResponse({ message: errorContent, code: errorCode }, ''));
      });
  }

  signOut() {
    Auth.signOut()
      .then(() => {
        this.clearData();

        let urlencoded = new URLSearchParams();
        urlencoded.append('response_type', 'code');
        urlencoded.append('client_id', environment.oAuth.clientId);
        urlencoded.append('scope', 'openid');
        urlencoded.append('redirect_uri', environment.oAuth.logoutRedirectUri);

        window.location.replace(environment.oAuth.logoutUrl + '?' + urlencoded.toString());
      })
      .catch((error) => {
        console.log(error);
      });
  }

  clearData() {
    localStorage.removeItem('lastApiCallTime');
    localStorage.removeItem('tokens');
  }

  getJWT(): Promise<string> {
    return Auth.currentSession()
      .then((currentSession) => {
        if (currentSession) {
          return Promise.resolve(currentSession.getIdToken().getJwtToken());
        } else {
          return Promise.reject('');
        }
      })
      .catch(() => {
        const idToken = this.getIdToken();
        if (idToken) {
          return Promise.resolve(idToken);
        } else {
          return Promise.reject('');
        }
      });
  }

  getJWTObj(): Promise<any> {
    return this.getJWT()
      .then((token) => {
        return (JSON.parse(atob(token.split('.')[1])));
      });
  }

  getCurrentUser(): Promise<IUser> {
    return this.isValidSession()
      .then(() => {
        return Auth.currentAuthenticatedUser()
          .then((userInfo) => {
            if (userInfo) {
              let user: IUser = {
                id: parseInt(_.get(userInfo, 'attributes.custom:employee_id', _.get(userInfo, 'employeeId', 0))),
                firstName: _.get(userInfo, 'attributes.given_name', _.get(userInfo, 'name', '...')),
                lastName: _.get(userInfo, 'attributes.family_name', _.get(userInfo, 'lastName', '...')),
                language: DATA_LANGUAGES_SERVICE.keys.english_au
              };
              user.formattedName = user.firstName + ' ' + user.lastName;

              //Add Modules
              const modules: IUserModule[] = [
                { id: DATA_MODULES_SERVICE.files.id, permissionLevel: DATA_PERMISSION_LEVELS.values.basic },
                { id: DATA_MODULES_SERVICE.emails.id, permissionLevel: DATA_PERMISSION_LEVELS.values.basic },
              ];
              user.modules = modules;

              return Promise.resolve(user);
            } else {
              return Promise.reject(null);
            }
          })
          .catch((error) => {
            return Promise.reject(this.getErrorResponse(error, 'getCurrentUser()'));
          });
      })
      .catch((error) => {
        return Promise.reject(this.getErrorResponse(error, 'currentAuthenticatedUser()'));
      });
  }

  hasPermission(user: IUser, moduleId: string, permissionLevel: number): boolean {
    const userModule: IUserModule = _.find(_.get(user, 'modules', []), { id: moduleId });
    return userModule && userModule.permissionLevel >= permissionLevel;
  }

  codeSignIn(code: string) {
    let urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'authorization_code');
    urlencoded.append('client_id', environment.oAuth.clientId);
    urlencoded.append('code', code);
    urlencoded.append('redirect_uri', environment.oAuth.redirectUri);

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return this.http.post(environment.oAuth.tokenUrl, urlencoded.toString(), { headers }).toPromise()
      .then((tokens) => {
        //Store tokens
        this.storeTokens(tokens);

        //Sign in with id token
        return this.federatedSignIn(this.getIdToken());
      })
      .catch((error) => {
        return Promise.reject(this.getErrorResponse(error));
      });
  }

  federatedSignIn(idToken?: string) {
    const idTokenObj = JSON.parse(atob(idToken.split('.')[1]));
    if (idTokenObj) {
      return Auth.signOut()
        .then(() => {
          const federatedUser: IFederatedUser = {
            name: idTokenObj['given_name'] ? idTokenObj['given_name'] : '',
            email: idTokenObj['email'] ? idTokenObj['email'] : '',
            lastName: idTokenObj['family_name'] ? idTokenObj['family_name'] : '',
            employeeId: idTokenObj['custom:employee_id'] ? parseInt(idTokenObj['custom:employee_id']) : 0
          };

          // expires_at means the timestamp when the token provided expires,
          // here we can derive it from the expiresIn parameter provided,
          // then convert its unit from second to millisecond, and add the current timestamp
          return Auth.federatedSignIn(environment.oAuth.cognitoPoolUrl, { token: idToken, expires_at: idTokenObj['exp'] * 1000 }, federatedUser)
            .then(() => {
              return this.getCurrentUser()
                .then((user) => {
                  this.globalVarsService.setUser(user);
                })
                .catch((error) => {
                  return Promise.reject(this.getErrorResponse(error));
                });
            })
            .catch((error) => {
              return Promise.reject(this.getErrorResponse(error));
            });
        });
    } else {
      return Promise.reject(null);
    }
  }

  async refreshTokens() {
    //requestingToken is a flag that could be better handled by a global state management
    if (this.requestingTokens) {
      return;
    }

    this.requestingTokens = true;

    let urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'refresh_token');
    urlencoded.append('client_id', environment.oAuth.clientId);
    urlencoded.append('refresh_token', this.getRefreshToken());

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return await this.http.post(environment.oAuth.tokenUrl, urlencoded.toString(), { headers }).toPromise()
      .then(async (tokens) => {
        //Store tokens
        this.storeTokens(tokens);

        await this.federatedSignIn(this.getIdToken());

        this.requestingTokens = false;
        return Promise.resolve();
      })
      .catch((error) => {
        this.requestingTokens = false;
        return Promise.reject(this.getErrorResponse(error));
      });
  }

  storeTokens(tokens: any) {
    let newTokens: any = {
      access_token: tokens.access_token,
      id_token: tokens.id_token,
      refresh_token: ''
    };

    if (tokens && tokens.refresh_token) {
      newTokens.refresh_token = tokens.refresh_token;
    } else {
      newTokens.refresh_token = this.getRefreshToken();
    }

    localStorage.setItem('tokens', JSON.stringify(newTokens));
  }

  getTokensObj() {
    const tokens = localStorage.getItem('tokens');
    return JSON.parse(tokens ? tokens : '{}');
  }

  getIdToken() {
    return _.get(this.getTokensObj(), 'id_token', null);
  }

  getRefreshToken() {
    return _.get(this.getTokensObj(), 'refresh_token', null);
  }

  getAccessToken() {
    return _.get(this.getTokensObj(), 'access_token', null);
  }
}