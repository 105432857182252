import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//NgZorro modules
import { NgZorroModule } from 'src/app/ng-zorro.module';

import { SWLoadingComponent } from './loading/sw-loading.component';
import { SWSkeletonComponent } from './skeleton/sw-skeleton.component';
import { SWWorkerComponent } from './worker/sw-worker.component';
import { SWFormTooltipComponent } from './form-tooltip/sw-form-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,

    //NgZorro modules
    NgZorroModule
  ],

  declarations: [
    //Components
    SWLoadingComponent,
    SWSkeletonComponent,
    SWWorkerComponent,
    SWFormTooltipComponent
  ],

  exports: [
    //Components
    SWLoadingComponent,
    SWSkeletonComponent,
    SWWorkerComponent,
    SWFormTooltipComponent
  ],

  entryComponents: [
    //Components
    SWLoadingComponent,
    SWSkeletonComponent,
    SWWorkerComponent,
    SWFormTooltipComponent
  ]
})
export class ComponentsModule { }