//Wrapper for the AWS Amplify API category https://aws-amplify.github.io/amplify-js/media/api_guide
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ConsoleService } from '../../console.service';
import * as _ from 'lodash';

import API from '@aws-amplify/api';
import { AWSClass, IAWSResponse, IAWSError, AWS_CLASS } from '../aws-class';
import { AWSAuthService, AWS_AUTH } from './aws-helper-auth';
import { ModalService } from '../../modal.service';
import { DateTimeService } from '../../../../shared/services/date-time.service';

export interface IAWSAPIRequestOpts {
  apiName?: string,
  path?: string,
  anonymous?: boolean,
  body?: {},
  headers?: {},
  queryStringParams?: {},
}

@Injectable()
export class AWSAPIService extends AWSClass {
  constructor(
    public AuthService: AWSAuthService,
    public modalService: ModalService,
    private dateTimeService: DateTimeService,
    public consoleService: ConsoleService
  ) {
    //Call extending class constructor
    super(modalService, consoleService);

  }

  checkSessionTimeout() {
    let lastApiCallTime = parseInt(localStorage.getItem('lastApiCallTime'));
    let currentTime = this.dateTimeService.get().getTime();
    if (lastApiCallTime && (currentTime - lastApiCallTime >= environment.session.timeout)) {
      this.AuthService.signOut();
      throw ('Session Timeout');
    }

    localStorage.setItem('lastApiCallTime', currentTime.toString());

    this.checkTokenLifetime()
      .then((tokensNeedRefresh) => {
        if (tokensNeedRefresh) {
          this.AuthService.refreshTokens();
        }
      });
  }

  checkTokenLifetime(): Promise<boolean> {
    return this.AuthService.getJWTObj()
      .then((tokenObj) => {
        const currentTime = this.dateTimeService.get().getTime();
        // check if token already expired
        if (currentTime >= tokenObj.exp * 1000) {
          return Promise.resolve(true);
        }

        const elapsedtime = currentTime - tokenObj.iat * 1000;
        // does it need refreshing?
        if (elapsedtime >= environment.session.refreshTime) {
          return Promise.resolve(true);
        }

        return Promise.resolve(false);
      });
  }

  initOptions(options: IAWSAPIRequestOpts): Promise<IAWSAPIRequestOpts> {
    //API name
    options.apiName = _.get(options, 'apiName', environment.api.name);

    //Headers
    options.headers = _.get(options, 'headers', {});

    //Content type
    if (!options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }

    //Check if request is anonymous
    if (_.get(options, 'anonymous', false) === true) {
      return Promise.resolve(options);
    } else {
      //Get JWT to authenticate request
      return this.AuthService.getJWT()
        .then((jwt) => {
          //Add Authorization header
          if (!options.headers['Authorization']) {
            options.headers['Authorization'] = jwt;
          }

          return Promise.resolve(options);
        })
        .catch(() => {
          return Promise.reject(this.getErrorResponse({ response: { status: AWS_AUTH.SIGN_IN_ERROR_UNKNOW } }, 'initOptions'));
        });
    }
  }

  post(options: IAWSAPIRequestOpts): Promise<IAWSResponse> {
    this.checkSessionTimeout();

    //Init options
    return this.initOptions(options)
      .then((options) => {
        return API.post(options.apiName, options.path, { body: options.body, headers: options.headers })
          .then((response: IAWSResponse) => {
            return Promise.resolve(response);
          })
          .catch((error) => {
            return Promise.reject(this.getErrorResponse(error, 'post'));
          });
      });
  }

  get(options: IAWSAPIRequestOpts): Promise<IAWSResponse> {
    this.checkSessionTimeout();

    //Init options
    return this.initOptions(options)
      .then((options) => {
        //Query string params
        if (!options.queryStringParams) {
          options.queryStringParams = {};
        }

        return API.get(options.apiName, options.path, { headers: options.headers, queryStringParameters: options.queryStringParams })
          .then((response: IAWSResponse) => {
            return Promise.resolve(response);
          })
          .catch((error) => {
            return Promise.reject(this.getErrorResponse(error, 'get'));
          });
      });
  }

  getURL(options: IAWSAPIRequestOpts): Promise<string> {
    //Init options
    return this.initOptions(options)
      .then((options) => {
        //Check if there are query string params
        let queryString: string = '';
        if (options.queryStringParams) {
          queryString += '?';

          for (let key in options.queryStringParams) {
            queryString += key + '=' + options.queryStringParams[key] + '&';
          }

          //Remove last '&'
          queryString = queryString.slice(0, -1);
        }

        return API.endpoint(options.apiName)
          .then((endpoint) => {
            return endpoint + '/' + options.path + queryString;
          });
      });
  }

  getErrorResponse(error: any, origin?: string, code?: number): IAWSResponse {
    //Check for error
    let errorBody: IAWSError = {
      code: code ? code : _.get(error, 'code', null),
      description: _.get(error, 'message', null)
    };

    //Set message if error is handled
    switch (code) {
      case AWS_CLASS.RESPONSES.CODES.AUTH_401:
        //Unauthorized
        errorBody.description = 'Unathorized user. Please try again.';
        break;
      case AWS_AUTH.SIGN_IN_ERROR_UNKNOW:
        //No JWT found
        errorBody.description = this.getCouldNotConnectError() + ' ' + 'Please try again later.';
        break;
    }

    return super.getErrorResponse(error, origin, code);
  }
}