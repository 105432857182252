import { Injectable } from '@angular/core';
import { NzNotificationService, NzNotificationDataOptions } from 'ng-zorro-antd';

export const NOTIFICATION_SERVICE = {
  types: {
    blank: 'blank',
    info: 'info',
    success: 'success',
    error: 'error',
    warning: 'warning'
  }
}

export interface INotificationOptions extends NzNotificationDataOptions {
  title: string,
  content: string,
  type?: string
}

@Injectable()
export class NotificationService {
  constructor(
    private nzNotificationService: NzNotificationService
  ) { }

  initOptions(options?: INotificationOptions): INotificationOptions {
    if (!options) {
      options = <INotificationOptions>{};
    }

    //Type
    if (!options.type) {
      options.type = NOTIFICATION_SERVICE.types.blank;
    }

    //Content
    if (!options.content) {
      options.content = '';
    }

    //Title
    if (!options.title) {
      options.title = null;
    }

    return options;
  }

  presentSuccess(options?: INotificationOptions) {
    options = this.initOptions(options);
    options.type = NOTIFICATION_SERVICE.types.success;
    this.present(options);
  }

  presentError(options?: INotificationOptions) {
    options = this.initOptions(options);
    options.type = NOTIFICATION_SERVICE.types.error;
    this.present(options);
  }

  presentWarning(options?: INotificationOptions) {
    options = this.initOptions(options);
    options.type = NOTIFICATION_SERVICE.types.warning;
    this.present(options);
  }

  present(options?: INotificationOptions) {
    options = this.initOptions(options);

    switch (options.type) {
      //Success
      case NOTIFICATION_SERVICE.types.success:
        this.nzNotificationService.success(options.title, options.content, options);
        break;
      //Error
      case NOTIFICATION_SERVICE.types.error:
        this.nzNotificationService.error(options.title, options.content, options);
        break;
      //Warning
      case NOTIFICATION_SERVICE.types.warning:
        this.nzNotificationService.warning(options.title, options.content, options);
        break;
      //Info
      default:
        this.nzNotificationService.info(options.title, options.content, options);
        break;
    }
  }
}