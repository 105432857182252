import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

//NgZorro modules
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@NgModule({
  exports: [
    CommonModule,

    //NgZorro modules
    NzButtonModule,
    NzFormModule,
    NzTableModule,
    NzCardModule,
    NzAvatarModule,
    NzBadgeModule,
    NzPopoverModule,
    NzTagModule,
    NzTimelineModule,
    NzToolTipModule,
    NzDropDownModule,
    NzTabsModule,
    NzInputModule,
    NzDrawerModule,
    NzListModule,
    NzIconModule,
    NzModalModule,
    NzSelectModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzRadioModule,
    NzSpinModule,
    NzNotificationModule,
    NzMessageModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzProgressModule,
    NzDescriptionsModule,
    NzPopconfirmModule,
    NzDividerModule,
    NzEmptyModule,
    NzTimePickerModule,
    NzUploadModule,
    NzInputNumberModule,
    NzBreadCrumbModule,
    NzCheckboxModule,
    NzMenuModule,
    NzAlertModule,
  ],

  imports: [
    CommonModule,

    //NgZorro modules
    NzButtonModule,
    NzFormModule,
    NzTableModule,
    NzCardModule,
    NzAvatarModule,
    NzBadgeModule,
    NzPopoverModule,
    NzTagModule,
    NzTimelineModule,
    NzToolTipModule,
    NzDropDownModule,
    NzTabsModule,
    NzInputModule,
    NzDrawerModule,
    NzListModule,
    NzIconModule,
    NzModalModule,
    NzSelectModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzRadioModule,
    NzSpinModule,
    NzNotificationModule,
    NzMessageModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzProgressModule,
    NzDescriptionsModule,
    NzPopconfirmModule,
    NzDividerModule,
    NzEmptyModule,
    NzTimePickerModule,
    NzUploadModule,
    NzInputNumberModule,
    NzBreadCrumbModule,
    NzCheckboxModule,
    NzMenuModule,
    NzAlertModule,
  ]
})
export class NgZorroModule { }