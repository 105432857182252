import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

//Hammer
import 'hammerjs';

//AWS Amplify config
import Amplify from 'aws-amplify';
import Predictions, { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);
Auth.configure(awsmobile);
API.configure(awsmobile);
Amplify.addPluggable(new AmazonAIPredictionsProvider());