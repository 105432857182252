import { Injectable } from '@angular/core';

@Injectable()
export class ConsoleService {
  log(info: any, title?: string) {
    if (title) {
      console.log(title, info);
    } else {
      console.log(info);
    }
  }

  error(info: any, title?: string) {
    if (title) {
      console.error(title, info);
    } else {
      console.error(info);
    }
  }
}