import { Injectable } from '@angular/core';

export const QUILL_COLORS = [
  '#000000',
  '#666666',
  '#e0e0e0',
  '#ffffff',
  '#c63527',
  '#941100',
  '#692122',
  '#535f6a',
  '#001489',
  '#a0c3e3',
  '#0ba247',
  '#87f3b2',
  '#ffce00',
  '#fff1b8',
];

export const QUILL_MODULES = {
  toolbar: [
    //Font size
    [
      { 'header': [1, 2, 3, 4, false] }
    ],

    //Font format
    [
      'bold',
      'italic',
      'underline',
      //'strike',
    ],

    //Link
    ['link'],

    //Colors
    [
      { 'color': QUILL_COLORS },
      { 'background': QUILL_COLORS }
    ],

    //Clean format
    ['clean'],
  ]
};

export const QUILL_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'link',
  'color',
  'background'
];

@Injectable()
export class QuillService {
  getModules() { 
    return JSON.parse(JSON.stringify(QUILL_MODULES));
  }

  getFormats() { 
    return QUILL_FORMATS;
  }
}