import { ModalService, IModalOptions } from '../modal.service';
import * as _ from 'lodash';

import { ConsoleService } from '../console.service';

export interface IAWSResponse {
  status: string,
  code: number,
  body: any,
  message: string
}

export interface IAWSError {
  code: number,
  description: string
}

export const AWS_CLASS = {
  RESPONSES: {
    STATUSES: {
      SUCCESS: 'success',
      ERROR: 'error'
    },

    CODES: {
      UNHANDLED: -1,

      //Status
      STATUS_200: 200, //for when everything is okay
      STATUS_204: 204, //for when everything is okay but there’s no content to return
      STATUS_500: 500, //for when the server throws an error, completely unexpected

      //Data
      DATA_400: 400, //for when the requested information is incomplete or malformed
      DATA_404: 404, //for when everything is okay, but the resource doesn’t exist

      //Authentication
      AUTH_401: 401, //for when an access token isn’t provided, or is invalid
      AUTH_403: 403 //for when an access token is valid, but requires more privileges
    }
  },
}

export abstract class AWSClass {
  constructor(
    public modalService: ModalService,
    public consoleService: ConsoleService
  ) { }

  handleResponse(apiResponse: IAWSResponse, origin?: string): Promise<IAWSResponse> {
    this.consoleService.log(apiResponse, (origin ? (origin + ' response ->') : ''));

    //Check for error
    if (apiResponse.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
      //Success
      return Promise.resolve(apiResponse);
    } else {
      //Error
      return Promise.reject(this.getErrorResponse(apiResponse, origin));
    }
  }

  getSuccessResponse(body?: any, code?: number, message?: string): IAWSResponse {
    //Init response
    const response: IAWSResponse = {
      status: AWS_CLASS.RESPONSES.STATUSES.SUCCESS,
      code: code ? code : AWS_CLASS.RESPONSES.CODES.STATUS_200,
      body: body,
      message: (!message && code === AWS_CLASS.RESPONSES.CODES.STATUS_200) ? 'OK' : message,
    };

    return response;
  }

  getErrorResponse(error: any, origin?: string, code?: number, showModal?: boolean): IAWSResponse {
    //Init response
    const errorResponse: IAWSResponse = {
      status: AWS_CLASS.RESPONSES.STATUSES.ERROR,
      code: code ? code : _.get(error, 'code', AWS_CLASS.RESPONSES.CODES.UNHANDLED),
      body: _.get(error, 'body', error),
      message: _.get(error, 'body.description', _.get(error, 'message', 'Unhandled error happened')),
    };

    //Print response
    this.consoleService.error(error, 'Error in ' + (origin ? origin : '(not provided)'));

    if (showModal) {
      const options: IModalOptions = {
        nzTitle: 'Error',
        nzContent: errorResponse.message
      };
      this.modalService.presentError(options);
    }

    return errorResponse;
  }

  getCouldNotConnectError(): string {
    return 'Could not make a connection to the backend right now.';
  }
}