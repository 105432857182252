import { Component, OnInit } from '@angular/core';

import { DateTimeService } from './shared/services/date-time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  year: number = 0;

  constructor(
    private dateTimeService: DateTimeService,
  ) { }

  ngOnInit() {
    this.year = this.dateTimeService.getYear(this.dateTimeService.get());
  }
}