import { Injectable } from '@angular/core';

export interface IDataCountry {
  id: number,
  name: string,
  code: string,
  color: string,
  pic: string
}

@Injectable({
  providedIn: 'root'
})
export class DataCountriesService {
  constructor() { }

  get(): Promise<IDataCountry[]> {
    const countries: IDataCountry[] = [
      { id: 1, name: 'Australia', code: 'AU', color: 'green', pic: 'assets/images/flags/au_flat.png', },
      //{ id: 2, name: gettext('United States of America'), code: 'US', color: 'purple', pic: 'assets/images/flags/us_flat.png', },
      //{ id: 3, name: gettext('United Kingdom'), code: 'UK', color: 'red', pic: 'assets/images/flags/uk_flat.png', },
      { id: 4, name: 'New Zealand', code: 'NZ', color: 'orange', pic: 'assets/images/flags/nz_flat.png', },
      //{ id: 5, name: gettext('Venezuela'), code: 'VE', color: 'red', pic: 'assets/images/flags/ve_flat.png', }
    ];

    return Promise.resolve(countries);
  }
}