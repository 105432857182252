import { Component, OnInit } from '@angular/core';
import { DateTimeService } from 'src/app/shared/services/date-time.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  year: number = 0;
  appVersion: string = environment.appVersion;

  constructor(
    private dateTimeService: DateTimeService,
  ) { }

  ngOnInit(): void {
    //Set year
    this.year = this.dateTimeService.getYear(this.dateTimeService.get());
  }
}