import { Injectable } from '@angular/core';

export const DATA_LANGUAGES_SERVICE = {
  keys: {
    english_au: 'en_AU',
    spanish_us: 'es_US'
  }
}

export interface IDataLanguage {
  code: string,
  name: string,
  color: string,
  pic: string
}

@Injectable({
  providedIn: 'root'
})
export class DataLanguagesService {
  constructor() { }

  get(): IDataLanguage[] {
    const languages: IDataLanguage[] = [
      { code: DATA_LANGUAGES_SERVICE.keys.english_au, name: 'English (AU)', color: 'green', pic: 'assets/images/flags/au_flat.png' },
      { code: DATA_LANGUAGES_SERVICE.keys.spanish_us, name: 'Español (US)', color: 'purple', pic: 'assets/images/flags/us_flat.png' }
    ];

    return languages;
  }

  getDefaultCode() {
    return DATA_LANGUAGES_SERVICE.keys.english_au;
  }
}