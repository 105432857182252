import { NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

//NgZorro language
import { NgZorroModule } from './ng-zorro.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
registerLocaleData(en);

//Main component
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//Layouts
import { CommonLayoutComponentsModule } from './layouts/common-layout/components/layouts-components.module';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

//Shared module
import { SharedModule } from './shared/shared.module';

//Services
import { ThemeConstantService } from './shared/services/theme-constant.service';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonLayoutComponentsModule,
    SharedModule,

    //NgZorro modules
    NgZorroModule
  ],

  providers: [
    { provide: NZ_I18N, useValue: en_US, },
    ThemeConstantService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }