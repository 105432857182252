import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { AWSAuthService } from '../services/aws/helpers/aws-helper-auth';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    public authService: AWSAuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isValidSession()
      .then(() => {
        return true;
      })
      .catch(() => {
        this.authService.signOut();
        return false;
      });
  }
}