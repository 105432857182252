<footer class="footer">
  <div class="footer-content justify-content-between">
    <p class="m-b-0">
      Copyright© {{ year }} Swissport. All rights reserved.
      <br>
      Version {{ appVersion }}
    </p>
    <!-- 
    <span>
      <a href="" class="text-gray m-r-15">{{ "Terms & Conditions" }}</a>
      <a href="" class="text-gray">{{ "Privacy Policy" }}</a>
    </span>
    -->
  </div>
</footer>