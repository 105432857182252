import { Routes } from '@angular/router';

import { FILES_ROUTES } from 'src/app/modules/files/files-routes';
import { EMAILS_ROUTES } from 'src/app/modules/emails/emails-routes';

export const CommonLayoutRoutes: Routes = [
  //Files
  {
    path: FILES_ROUTES.base.url,
    loadChildren: () => import('../../modules/files/files.module').then(m => m.FilesModule),
    data: {
      title: 'Files'
    }
  },

  //Emails
  {
    path: EMAILS_ROUTES.base.url,
    loadChildren: () => import('../../modules/emails/emails.module').then(m => m.EmailsModule),
    data: {
      title: 'Emails'
    }
  }
];