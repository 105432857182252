//Import environment
import { environment } from 'src/environments/environment';

const awsmobile = {
  "aws_project_region": "ap-southeast-2",

  //Add Auth config
  Auth: environment.amplify.Auth,

  //Add API config
  API: environment.amplify.API
};

export default awsmobile;