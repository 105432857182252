import { Component, OnInit, Input } from '@angular/core';

export const SW_LOADING = {
  types: {
    single: 'single',
    multiple: 'multiple'
  }
};

@Component({
  selector: 'sw-form-tooltip',
  templateUrl: './sw-form-tooltip.component.html'
})
export class SWFormTooltipComponent implements OnInit {
  @Input() content: string = '';

  constructor() { }

  ngOnInit() { }
}