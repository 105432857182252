import { Injectable } from '@angular/core';
import { NzModalService, NzModalRef, ModalOptions } from 'ng-zorro-antd';

import { SWLoadingComponent } from '../components/loading/sw-loading.component';

export const MODAL_SERVICE = {
  types: {
    info: 'info',
    success: 'success',
    error: 'error',
    warning: 'warning',
    confirm: 'confirm'
  }
}

export interface IModalOptions extends ModalOptions {
  type?: string,
  skipDismissPrevious?: boolean
}

@Injectable()
export class ModalService {
  hardLoaderRef: NzModalRef = null;
  alertRef: NzModalRef = null;

  constructor(
    public nzModalService: NzModalService
  ) { }

  initOptions(options?: IModalOptions): IModalOptions {
    if (!options) {
      options = <IModalOptions>{};
    }

    return options;
  }

  presentError(options?: IModalOptions) {
    options = this.initOptions(options);
    options.type = MODAL_SERVICE.types.error;
    this.present(options);
  }

  presentWarning(options?: IModalOptions) {
    options = this.initOptions(options);
    options.type = MODAL_SERVICE.types.warning;
    this.present(options);
  }

  presentConfirm(options?: IModalOptions) {
    options = this.initOptions(options);
    options.type = MODAL_SERVICE.types.confirm;
    this.present(options);
  }

  present(options?: IModalOptions) {
    //Init options
    options = this.initOptions(options);

    //Dismiss previous
    if (!options.skipDismissPrevious) {
      this.dismiss();
    }

    //Set options
    options.nzTitle = options.nzTitle ? options.nzTitle : null;
    options.nzContent = options.nzContent ? options.nzContent : null;
    options.nzClosable = options.nzClosable ? options.nzClosable : false;
    options.nzMaskClosable = options.nzMaskClosable ? options.nzMaskClosable : false;
    options.nzOkText = options.nzOkText ? options.nzOkText : 'Ok';
    options.nzCancelText = options.nzCancelText === null ? null : (options.nzCancelText ? options.nzCancelText : 'Cancel');
    options.nzWrapClassName = 'vertical-center-modal';

    switch (options.type) {
      //Success
      case MODAL_SERVICE.types.success:
        options.nzTitle = options.nzTitle === null ? 'Success' : options.nzTitle;
        this.alertRef = this.nzModalService.success(options);
        break;
      //Error
      case MODAL_SERVICE.types.error:
        options.nzTitle = options.nzTitle === null ? 'Error' : options.nzTitle;
        this.alertRef = this.nzModalService.error(options);
        break;
      //Warning
      case MODAL_SERVICE.types.warning:
        options.nzTitle = options.nzTitle === null ? 'Warning' : options.nzTitle;
        this.alertRef = this.nzModalService.warning(options);
        break;
      //Confirm
      case MODAL_SERVICE.types.confirm:
        options.nzTitle = options.nzTitle === null ? 'Confirm' : options.nzTitle;
        this.alertRef = this.nzModalService.confirm(options);
        break;
      //Info
      default:
        options.nzTitle = options.nzTitle === null ? 'Info' : options.nzTitle;
        this.alertRef = this.nzModalService.info(options);
        break;
    }
  }

  dismiss() {
    if (this.alertRef) {
      this.alertRef.close();
      this.alertRef = null;
    }
  }

  presentHardLoader(options?: IModalOptions) {
    options = this.initOptions(options);

    options.nzComponentParams = { content: options.nzContent ? options.nzContent : 'Loading...' };
    options.nzContent = SWLoadingComponent;
    options.nzMask = true;
    options.nzClosable = false;
    options.nzMaskClosable = false;
    options.nzFooter = null;
    options.nzWrapClassName = 'vertical-center-modal';

    this.dismissHardLoader();
    this.hardLoaderRef = this.nzModalService.create(options);
  }

  updateHardLoaderContent(content: string) {
    if (this.hardLoaderRef) {
      let hardLoaderContent: SWLoadingComponent = this.hardLoaderRef.getContentComponent();
      if (hardLoaderContent) {
        hardLoaderContent.content = content;
      }
    }
  }

  dismissHardLoader() {
    if (this.hardLoaderRef) {
      this.hardLoaderRef.close();
      this.hardLoaderRef = null;
    }
  }
}