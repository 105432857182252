import { Injectable } from '@angular/core';

export const DATA_PERMISSION_LEVELS = {
  codes: {
    basic: 'basic',
    medium: 'medium',
    full: 'full'
  },

  values: {
    basic: 0,
    medium: 50,
    full: 100
  }
}

export interface IDataPermissionLevel {
  code: string,
  name: string,
  value: number
}

@Injectable({
  providedIn: 'root'
})
export class DataPermissionLevelsService {
  constructor() { }

  get(): IDataPermissionLevel[] {
    const permissionLevels: IDataPermissionLevel[] = [
      { code: DATA_PERMISSION_LEVELS.codes.basic, name: 'Basic', value: DATA_PERMISSION_LEVELS.values.basic },
      { code: DATA_PERMISSION_LEVELS.codes.medium, name: 'Medium', value: DATA_PERMISSION_LEVELS.values.medium },
      { code: DATA_PERMISSION_LEVELS.codes.full, name: 'Full', value: DATA_PERMISSION_LEVELS.values.full }
    ];

    return permissionLevels;
  }
}